<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="contractMenu">
        <el-button @click="exportlist" class="rightMenu" type="text" >导出</el-button>
    </div>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request, exportForm } from '@/assets/js/http.js'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'Notice',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.noticeList()
  },

  methods: {
    // 获取质量协同-采购-质量通知单数据列表
    noticeList () {
      let startTime = ''
      let endTime = ''
      if (this.childData.searchData.createDate) {
        console.log(this.childData.searchData.createDate[0])
        const myDate = new Date(this.childData.searchData.createDate[0])
        console.log(myDate)
        const Y = myDate.getFullYear()
        const M = myDate.getMonth() + 1
        const D = myDate.getDate()
        const curDay = Y + '-' + M + '-' + D

        const myDate1 = new Date(this.childData.searchData.createDate[1])
        const Y1 = myDate1.getFullYear()
        const M1 = myDate1.getMonth() + 1
        const D1 = myDate1.getDate()
        const curDay1 = Y1 + '-' + M1 + '-' + D1

        startTime = curDay
        endTime = curDay1
      }
      const obj = {
        qualityCode: this.childData.searchData.qualityCode,
        startTime: startTime,
        endTime: endTime,
        supplier: this.childData.searchData.supplier,
        type: this.childData.searchData.type,
        status: this.childData.searchData.status,
        plant: this.childData.searchData.plant
      }
      console.log(obj)
      request('/api/quality/list?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'GET', obj).then((res) => {
        if (res.code === '200') {
          console.log(res)
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'ApplyingDetails' }, query: { id: data.id, type: data.type } })
        // console.log(this.tableData)
      } else {
        this.$router.push({ params: { viewName: 'AddExceptionManage' }, query: { id: data.id } })
      }
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.noticeList()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.noticeList()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.noticeList()
    },
    exportlist () {
      exportForm('/api/quality/export', 'get').then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        eleLink.click()
        window.URL.revokeObjectURL(url)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contractMenu{
 position: relative;
 width: 100%;
height: 56px;
}
.rightMenu{
    position: absolute;
    right: 30px;
    top: 8px;
    font-size: 16px;
}
</style>
